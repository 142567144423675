<template>
    <layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Edit {{userData.username}}</h4>
                        <form @submit.prevent="updateUser">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Name</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Name"
                                                v-model="userData.name"

                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Username</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Username"
                                                v-model="userData.username"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <!-- <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Country</label>
                                        <input

                                                type="text"
                                                class="form-control"
                                                placeholder="Country"
                                                v-model="userData.country"
                                        />
                                    </div>
                                </div> -->
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Email</label>
                                        <input
                                                type="email"
                                                class="form-control"
                                                placeholder="Email"
                                                v-model="userData.email"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Password</label>
                                        <input
                                            v-model="password"
                                                type="password"
                                                class="form-control"
                                                placeholder="Password"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style="display: flex; justify-content: flex-end">
                                <button class="btn btn-primary">Save</button>
                            </div>
                            
                        </form>
                    </div>
                </div>
                <div class="lodader__content" v-if="loader">
                    <b-spinner style="width: 3rem; height: 3rem" class="loader" variant="success" label="Spinning"></b-spinner>
                </div>
                <!-- end card -->
            </div>
        </div>
    </layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import {axios_get, axios_put} from "../../../helpers/helper";


    /**
     * Form validation component
     */
    export default {
        page: {
            title: "Edit User",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, PageHeader },
        data() {
            return {
                userData:null,
                password:null,
                loader:false,
                title: "Edit User",
                items: [
                    {
                        text: "View Users",
                        href: "/users"
                    },
                    {
                        text: "Edit User",
                        active: true
                    }
                ],
            };
        },
        validations: {

        },
        methods: {
            updateUser(){
                this.loader = true
                if(this.password){
                    this.userData.password = this.password
                }
                console.log(this.userData)
                axios_put("/user/"+this.id,{...this.userData},
                    ()=>{
                        window.location.href = '/users'
                        /* axios_get("/user/"+this.id,{},(da)=>{
                                console.log(da);
                                this.userData=da;
                                console.log(this.userData);
                            },(e)=>{
                                console.log("error",e);
                            },()=>{
                                console.log("finishing");
                                this.loader = false
                            }
                        ); */
                    },(e)=>{
                        console.log("error",e);
                    },()=>{
                        console.log("finishing");
                        this.loader = false
                    }
                ) 

            },

        },
        mounted() {
            console.log(this.$route.params.id)
            this.id=this.$route.params.id
            console.log(this.id)
            axios_get("/user/"+this.id,{},(d)=>{
                    console.log(d);
                    this.userData=d;
                    console.log(this.userData);
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                }
            );
        }

    };
</script>

<style scoped>
    .lodader__content{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;
        height: 100%;
    }

    .loader{
        position: relative;
        left: 50%;
        top: 50%;
    }
</style>